import styled from "styled-components";

export const TitleSection = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
`;

export const TitleWrapper = styled.div`
  border-bottom: 0.5rem solid var(--esx-red);
  border-radius: 5px;
  margin-bottom: 2rem;
  width: fit-content;
`;
export const Title = styled.h1`
  color: var(--esx-black);
  font-size: var(--medium-title-size);
  font-weight: bold;
`;
