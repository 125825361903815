import React from "react";
import * as S from "./styled";

import PropTypes from "prop-types";

const BorderedTitle = ({ title, color, borderColor, align, dense }) => {
  return (
    <S.TitleSection style={{ justifyContent: align }}>
      <S.TitleWrapper
        style={
          dense
            ? { margin: 0, borderBottom: `0.5rem solid ${borderColor}` }
            : { borderBottom: `0.5rem solid ${borderColor}` }
        }
      >
        <S.Title style={{ color: color }}>{title || "Insert title"}</S.Title>
      </S.TitleWrapper>
    </S.TitleSection>
  );
};

BorderedTitle.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  align: PropTypes.string,
  dense: PropTypes.bool,
};

export default BorderedTitle;
