import styled from "styled-components";
import media from "styled-media-query";
import Img from "gatsby-image";

export const ContentWrapper = styled.section`
  background-image: ${(props) => props.theme.gradient};
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 2rem var(--default-padding-desktop);
  flex-wrap: wrap;
  ${media.lessThan("medium")`
  padding: 2rem var(--default-padding-mobile);
  `}
`;

export const MainSubtitle = styled.h1`
  font-size: var(--small-title-size);
  color: ${(props) => props.theme.textColor};
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
`;
export const FigureWrapper = styled(Img)`
  height: 30rem;
  width: 30rem;
  ${media.lessThan("medium")`
    height: 20rem;
    width: 20rem;
  `}
  ${media.lessThan("medium")`
    height: 90vw;
    width: 90vw;
  `}
`;

//themes
export const mainTheme = {
  color: "var(--esx-red)",
  fontColor: "var(--esx-black)",
  gradient: "#fafafa",
  textColor: "#fafafa",
};

ContentWrapper.defaultProps = {
  theme: {
    color: "var(--esx-red)",
    fontColor: "var(--esx-black)",
    gradient: "#fafafa",
    textColor: "#fafafa",
  },
};
