import styled from "styled-components";
import { ChevronDown } from "styled-icons/octicons/ChevronDown";
import { Link } from "gatsby";
import media from "styled-media-query";

export const ArrowDownIcon = styled(ChevronDown)`
  margin-left: 1rem;
  height: 1rem;
`;

//styled components
export const BannerWrapper = styled.div`
  height: auto;
  padding: 2rem var(--default-padding-desktop);
  width: 100%;
  background-color: ${(props) => props.theme.main};
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  ${media.lessThan("large")`
    padding: var(--default-padding-mobile);
    flex-direction: column;
    align-items: baseline;

  `}
 
  ${media.greaterThan("1200px")`
    padding-left: 16%;
    padding-right: 16%;
    
  `}
 
`;
export const TextWrapper = styled.div`
  ${media.lessThan("large")`
    margin-bottom: 4rem;
  `}
  @media screen and (min-width: 100px) and (max-width: 1170px){
    margin-bottom: -5rem;
    padding-left: 16%;
    
    width:900px;
  }
`;
export const Title = styled.h1`
  color: #fafafa;
  font-size: var(--medium-title-size);
  font-weight: bold;
`;
export const Subtitle = styled.h2`
  color: #fafafa;
`;

export const ButtonWrapper = styled.button`
  padding: 3%;
  @media screen and (min-width: 100px) and (max-width: 1170px){
  
    padding-left: 70%;

  }
`;
export const Button = styled(Link)`
  border: 2px solid #fafafa;
  font-size: var(--paragraph-size);
  color: #fafafa;
  padding: 0.5rem 2rem;
  border-radius: 100px;
  transition: all 0.3s;
  &:hover {
    background: #fafafa;
    color: ${(props) => props.theme.main};
  }
`;
export const ExternalLink = styled.a`
  border: 2px solid #fafafa;
  font-size: var(--paragraph-size);
  color: #fafafa;
  padding: 0.9rem 5rem;
  border-radius: 100px;
  transition: all 0.3s;
  &:hover {
    background: #fafafa;
    color: ${(props) => props.theme.main};
  }
`;

//themes
export const mainTheme = {
  main: "var(--esx-red)",
};

BannerWrapper.defaultProps = {
  theme: {
    main: "var(--esx-red)",
  },
};
