import styled from "styled-components";
import media from "styled-media-query";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { ChevronDown } from "styled-icons/octicons/ChevronDown";

export const ArrowDownIcon = styled(ChevronDown)`
  margin-left: 1rem;
  height: 1rem;
`;

export const ItemsWrapper = styled.section`
  background-image: ${(props) => props.theme.gradient};
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 2rem var(--default-padding-desktop);
  flex-wrap: wrap;
  ${media.lessThan("medium")`
  padding: 2rem var(--default-padding-mobile);
  `}
`;
export const ItemWrapper = styled.div`
  text-align: center;
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  border-radius: 5px;
  ${media.lessThan("large")`
    flex: 1 1 40%;
  `}
  ${media.lessThan("medium")`
    flex: 1 1 100%;
  `}
`;
export const ItemContentWrapper = styled.div`
  color: ${(props) => props.theme.textColor};
`;
export const ItemActionsWrapper = styled.div`
  color: ${(props) => props.theme.textColor};
`;
export const ItemTitleWrapper = styled.div``;
export const Border = styled.hr`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 5rem;
  background-color: transparent;
  margin: auto;
  padding-top: 1rem;
  border-bottom: 0.5rem solid ${(props) => props.theme.color};
`;
export const ItemTitle = styled.h1`
  font-size: var(--small-title-size);
  font-weight: bold;
`;
export const MainSubtitle = styled.h2`
  font-size: var(--small-title-size);
  color: ${(props) => props.theme.textColor};
  width: 100%;
  text-align: center;
`;
export const IconWrapper = styled.figure`
  max-height: 10rem;
`;
export const ItemText = styled.p`
  margin: 1rem 0;
  font-weight: lighter;
  strong {
    font-weight: bold;
    color: ${(props) => props.theme.boldTextColor};
  }
`;
export const ItemButton = styled(AnchorLink)`
  color: ${(props) => props.theme.color};
  font-size: var(--label-size);
  border: 1px solid ${(props) => props.theme.color};
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-transform: uppercase;
  &:hover {
    color: ${(props) => props.theme.color};
  }
`;

//themes
export const mainTheme = {
  color: "var(--esx-red)",
  fontColor: "var(--esx-black)",
  gradient: "#fafafa",
  textColor: "#fafafa",
  boldTextColor: "#fafafa",
};

ItemsWrapper.defaultProps = {
  theme: {
    color: "var(--esx-red)",
    fontColor: "var(--esx-black)",
    gradient: "#fafafa",
    textColor: "#fafafa",
    boldTextColor: "#fafafa",
  },
};
