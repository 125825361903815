import React from "react";
import * as S from "./styled";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import BorderedTitle from "../BorderedTitle";

const ItemColumns = ({
  items,
  children,
  theme,
  disableAnimation,
  mainTitle,
  mainSubtitle,
}) => (
  <ThemeProvider theme={theme || S.mainTheme}>
    <S.ItemsWrapper>
      {mainTitle ? (
        <BorderedTitle
          title={mainTitle}
          align="center"
          color={theme.color || "var(--esx-black)"}
          dense
          borderColor="transparent"
        />
      ) : null}
      {mainSubtitle ? <S.MainSubtitle>{mainSubtitle}</S.MainSubtitle> : null}
      {items.map((item, key) => (
        <S.ItemWrapper
          key={key}
          data-sal={disableAnimation ? "" : "zoom-in"}
          data-sal-duration="500"
          data-sal-delay={100 * (key + 1)}
          data-sal-easing="ease-in"
        >
          <S.ItemContentWrapper>
            <S.IconWrapper>{item.icon}</S.IconWrapper>
            {item.title ? (
              <>
                <S.ItemTitleWrapper>
                  <S.ItemTitle>{item.title}</S.ItemTitle>
                </S.ItemTitleWrapper>
                <S.Border />
              </>
            ) : null}
          </S.ItemContentWrapper>
          <S.ItemActionsWrapper>
            {item.description ? (
              <>
                <S.ItemText
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></S.ItemText>
              </>
            ) : null}
            {item.anchorLink ? (
              <S.ItemButton to={item.anchorLink} stripHash>
                Saiba mais
                {/* <S.ArrowDownIcon /> */}
              </S.ItemButton>
            ) : null}
          </S.ItemActionsWrapper>
        </S.ItemWrapper>
      ))}
      {children}
    </S.ItemsWrapper>
  </ThemeProvider>
);

ItemColumns.propTypes = {
  items: PropTypes.array,
  theme: PropTypes.object,
  mainTitle: PropTypes.string,
  mainSubtitle: PropTypes.string,
  disableAnimation: PropTypes.bool,
  children: PropTypes.node,
};

export default ItemColumns;
