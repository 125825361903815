import React from "react";
import "../bulma.scss";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeroSection from "../components/HeroSection";
import CTABanner from "../components/CtaBanner";
import { useStaticQuery, graphql } from "gatsby";
import ItemColumns from "../components/ItemColumns";
import Img from "gatsby-image";

import CatHorizontal from "../images/svgs/cat-horizontal.svg";
import IS from "../images/svgs/interact-store.svg";
import Monitoring from "../images/svgs/monitoring.svg";
import Analytics from "../images/svgs/analytics.svg";
import Custommers from "../images/svgs/atendimento.svg";
import Sales from "../images/svgs/vendas.svg";
import Marketing from "../images/svgs/marketing.svg";
import Compliance from "../images/svgs/compliance.svg";

import Figure from "../components/Figure";

const CatPage = () => {
  const {
    catImg,
    catNotebook,
    complianceImg,
    empowerImg,
    expImg,
    timeImg,
    catMiddleServices,
    teacherImg,
    catWoman,
    logoXp,
    logoPaschoalotto,
    seoImg,
  } = useStaticQuery(
    graphql`
      query {
        seoImg: file(relativePath: { eq: "thumbnail_cat_500x300px.png" }) {
          childImageSharp {
            fixed(width: 500, height: 300) {
              src
            }
          }
        }
        catImg: file(relativePath: { eq: "cover-cat-1_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 970, maxHeight: 720, fit: FILL, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        catNotebook: file(relativePath: { eq: "cat-notebook.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 1200
              maxHeight: 800
              quality: 100
              fit: CONTAIN
              background: "transparent"
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        complianceImg: file(
          relativePath: { eq: "cover-cat_img-1_960x720px.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 970, maxHeight: 720, fit: FILL, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        empowerImg: file(
          relativePath: { eq: "cover-cat_img-2_960x720px.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 970, maxHeight: 720, fit: FILL, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        expImg: file(relativePath: { eq: "cover-cat_img32_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 970, maxHeight: 720, fit: FILL, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        timeImg: file(relativePath: { eq: "cover-cat_img-4_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 970, maxHeight: 720, fit: COVER, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        catMiddleServices: file(
          relativePath: { eq: "cat-middle-services.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 700, maxHeight: 740, fit: CONTAIN, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        teacherImg: file(relativePath: { eq: "professor.jpg" }) {
          childImageSharp {
            fluid(
              maxWidth: 96
              maxHeight: 96
              quality: 100
              fit: COVER
              toFormat: WEBP
            ) {
              src
            }
          }
        }
        catWoman: file(relativePath: { eq: "cover-cat-2_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 1028, maxHeight: 722, fit: COVER, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logoXp: file(relativePath: { eq: "logo-xp.png" }) {
          childImageSharp {
            fixed(height: 43, width: 200, toFormat: WEBP) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        logoPaschoalotto: file(relativePath: { eq: "paschoalotto-logo.png" }) {
          childImageSharp {
            fixed(height: 56, width: 200, toFormat: WEBP) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  );
  const catThemeItemsDark = {
    main: "var(--esx-cat)",
    color: "var(--esx-cat)",
    boldTextColor: "#ad76f4",
    gradient: "var(--dark-gradient)",
    textColor: "#dcdcdc",
  };
  const catThemeItemsLightTitles = {
    main: "var(--esx-cat)",
    color: "var(--esx-cat)",
    gradient: "#fafafa",
    boldTextColor: "var(--esx-cat)",
    titleColor: "var(--esx-cat)",
    textColor: "var(--esx-black)",
  };
  const catThemeItemsLight = {
    main: "var(--esx-cat)",
    color: "var(--esx-cat)",
    boldTextColor: "var(--esx-cat)",
    gradient: "#fafafa",
    titleColor: "var(--esx-black)",
    textColor: "var(--esx-black)",
  };

  const toolsItems = [
    {
      icon: <IS width="80" height="80" />,
      title: "Interaction Recovery",
      description:
        "A <strong>CAT</strong> documenta e organiza automaticamente todas as interações realizadas, sejam em conversas de áudio ou texto. Recupere conversas inteiras ou lotes específicos, facilmente, através de <strong>filtros inteligentes e intuitivos</strong>, integrando ao processo de monitoria da <strong>CAT</strong> ou exportando para qualquer outra plataforma.",
    },
    {
      icon: <Monitoring width="80" height="80" />,
      title: "Optimized Monitoring",
      description:
        "Escute <strong>apenas o necessário</strong> para identificar padrões e anomalias no comportamento de seu time e de seus clientes. Realize <strong>buscas através de palavras chaves</strong> ou <strong>assuntos classificados automaticamente</strong>. A CAT transcreve o áudio e <strong>aponta os trechos</strong> que precisam ser monitorados <strong>diretamente no player</strong>, sem que o profissional repasse o áudio inteiro para identificar e monitorar as ligações.",
    },
    {
      icon: <Analytics width="80" height="80" />,
      title: "Customer Analytics",
      description:
        "A Inteligência Artificial da <strong>CAT</strong> transforma as interações realizadas com o cliente, <strong>faladas ou escritas</strong>, em dados utilizáveis. Analisamos, encontramos padrões e entregamos, por meio de relatórios visuais, <strong>informações valiosas para toda a sua organização.</strong>",
    },
  ];
  const areaItems = [
    {
      icon: <Custommers width="60" height="60" />,
      title: "Atendimento",
    },
    {
      icon: <Sales width="60" height="60" />,
      title: "Vendas",
    },
    {
      icon: <Marketing width="60" height="60" />,
      title: "Marketing",
    },
    {
      icon: <Compliance width="60" height="60" />,
      title: "Compliance",
    },
  ];
  const customerItems = [
    {},
    {
      icon: <Img fixed={logoPaschoalotto.childImageSharp.fixed} />,
    },
    {
      icon: <Img fixed={logoXp.childImageSharp.fixed} />,
    },
    {},
  ];

  return (
    <Layout>
      <SEO
        title="CAT - People Insights"
        image={seoImg}
        description="A CAT - Customer Analytics Tool é uma solução de IA que analisa as interações entre seus clientes e colaboradores, identificando padrões e fornecendo dados que geram insights sobre vendas, satisfação do cliente e melhorias de produtos e serviços, além de mitigar riscos da sua operação."
      />
      <HeroSection
        theme={catThemeItemsDark}
        title="Inteligência Omnichannel 
        com Speech Analytics"
        text="A <strong>CAT - Customer Analytics Tool</strong> é uma solução de IA que <strong>analisa as interações</strong> entre seus clientes e colaboradores, identificando padrões e <strong>fornecendo dados</strong> que <strong>geram insights</strong> sobre vendas, satisfação do cliente e melhorias de produtos e serviços, além de mitigar riscos da sua operação. 
        "
        fixedImage={catImg.childImageSharp.fluid}
        buttonText="Fale agora com a gente"
        buttonLink="/contato-cat"
        openAsExternalLink
        logo={<CatHorizontal width="180" height="80" />}
      />
      <HeroSection
        invert
        forceTextAlignLeft
        theme={catThemeItemsLightTitles}
        title="Utilidades da cat"
        text="
        <ul>
          <li>Analisar todos os pontos de interação com o cliente, através de canais de voz e texto, e obter relatórios visuais conclusivos sobre suas operações</li>
          <li>Identificar padrões de comportamento, reclamações recorrentes e pontos de atenção</li>
          <li>Antecipar os movimentos do mercado e desenvolver o seu negócio com outro nível de inteligência</li>
        </ul>
        "
        fixedImage={catNotebook.childImageSharp.fluid}
      />

      <ItemColumns
        items={toolsItems}
        theme={catThemeItemsDark}
        mainTitle="Conheça as ferramentas da CAT"
        mainSubtitle="Liberdade para montar a plataforma ideal para o seu negócio, escolha as capacidades"
      />
      <ItemColumns
        items={areaItems}
        theme={catThemeItemsLight}
        mainTitle="O seu desafio é a nossa expertise"
      />
      <CTABanner
        title="Tenha acesso às tecnologias da CAT"
        subtitle="Modernize-se como uma Fintech, agende uma demonstração"
        theme={catThemeItemsDark}
        buttonText="Fale com a gente"
        buttonLink="/contato-cat"
        openAsExternalLink
      />
      <HeroSection
        theme={catThemeItemsLight}
        title="<strong>Garanta</strong> o compliance da sua organização"
        text="Alinhe as funções e responsabilidades do Compliance aos objetivos estratégicos da sua empresa, através de <strong>monitoramento automatizado de canais de texto e voz</strong>, 
        Sem o viés humano e/ou risco de vazamentos, a <strong>CAT</strong> protege a integridade e os valores da sua empresa."
        fixedImage={complianceImg.childImageSharp.fluid}
      />
      <HeroSection
        invert
        theme={catThemeItemsLight}
        title="<strong>Empodere</strong> os times da sua empresa com <strong>Análise de Voz</strong>"
        text="Capacite as pessoas do seu time com dados e insights reais, para serem <strong>mais autônomas e eficientes</strong>. A Inteligência Artificial da <strong>CAT</strong> analisa a jornada do seu cliente, gera insights sobre cada ponto e colabora para a <strong>construção do Playbook ideal</strong> para cada área e objetivo do seu negócio."
        fixedImage={empowerImg.childImageSharp.fluid}
      />
      <HeroSection
        theme={catThemeItemsLight}
        title="<strong>Potencialize</strong> a experiência do seu cliente e <strong>diminua reclamações</strong>"
        text="Aperfeiçoe de forma contínua a sua relação com clientes, parceiros e fornecedores, focando em dores e desejos para <strong>criar experiências melhores e personalizadas</strong>. A Inteligência Artificial da <strong>CAT</strong> colabora para que o seu time de Customer Experience compreenda melhor o cliente, seus padrões de comportamento e a sua jornada em todos os canais de interações da sua empresa, sejam eles por voz ou texto."
        fixedImage={expImg.childImageSharp.fluid}
      />
      <HeroSection
        invert
        theme={catThemeItemsLight}
        title="<strong>Economize tempo</strong> e dinheiro com análises"
        text="A <strong>CAT</strong> une todos os dados estruturados (tabelas e bancos de dados) e não estruturados da sua empresa (voz, texto e imagem) em <strong>relatórios visuais conclusivos</strong>, que retratam todos os canais e pontos de interação com o cliente, substituindo grande parte das análises manuais por Inteligência Artificial."
        fixedImage={timeImg.childImageSharp.fluid}
      />
      <Figure
        theme={catThemeItemsLight}
        mainTitle="A CAT é descomplicada e intuitiva"
        mainSubtitle="Democratizamos o acesso às tecnologias avançadas"
        fluidImage={catMiddleServices.childImageSharp.fluid}
      />
      <CTABanner
        title="Tenha acesso às tecnologias da CAT"
        subtitle="Modernize-se como uma Fintech, agende uma demonstração"
        theme={catThemeItemsDark}
        buttonText="Fale com a gente"
        buttonLink="/contato-cat"
        openAsExternalLink
      />
      <ItemColumns
        items={customerItems}
        theme={catThemeItemsLight}
        mainTitle="Quem utiliza a CAT"
      />
      <HeroSection
        changeHeight="85vh"
        theme={catThemeItemsDark}
        title="Dados invisíveis para humanos, são nítidos para os olhos e ouvidos da <strong>Inteligência Artificial</strong>"
        text={`<blockquote>"O objetivo da cat é transformar o Big Data e a Ciência da Complexidade em algo fácil de entender e útil para o seu negócio, contribuindo para que empresas <strong>tomem decisões melhores</strong> e possam empenhar mais tempo sendo criativas e inovadoras, tendo sempre o cliente no centro de tudo."</blockquote><br/>
        <div class="media">
          <div class="media-left">
            <figure class="image is-96x96">
              <img class="is-rounded" src="${teacherImg.childImageSharp.fluid.src}" alt="André Carvalho">
            </figure>
          </div>
          <div class="media-content">
            <p class="title is-4">André Carvalho</p>
            <p class="subtitle is-6">CSO da CAT</p>
          </div>
        </div>
        `}
        fixedImage={catWoman.childImageSharp.fluid}
      />
    </Layout>
  );
};

export default CatPage;
