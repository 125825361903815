import React from "react";
import * as S from "./styled";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

const CtaBanner = ({
  title,
  subtitle,
  buttonText,
  buttonLink,
  theme,
  openAsExternalLink,
}) => (
  <ThemeProvider theme={theme || S.mainTheme}>
    <S.BannerWrapper>
      <S.TextWrapper>
        <S.Title>{title || "Insert title"}</S.Title>
        <S.Subtitle>{subtitle || "Insert subtitle"}</S.Subtitle>
      </S.TextWrapper>

      <S.ButtonWrapper>
        {openAsExternalLink ? (
          <S.ExternalLink href={buttonLink || "/"}>
            {buttonText || "insert text"}
          </S.ExternalLink>
        ) : (
          <S.Button to={buttonLink || "/"}>
            {buttonText || "insert text"}
          </S.Button>
        )}
      </S.ButtonWrapper>
    </S.BannerWrapper>
  </ThemeProvider>
);

CtaBanner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  theme: PropTypes.object,
  openAsExternalLink: PropTypes.bool,
};

export default CtaBanner;
