import styled from "styled-components";
import media from "styled-media-query";

export const LayoutWrapper = styled.section`
  display: flex;
`;

export const LayoutMain = styled.main`
  background: #fafafa;
  padding-top: 4.4rem;
  width: 100%;  
  ${media.greaterThan("2000px")`
    padding-left: 10%;
    padding-right: 10%;
  `}
`;
