import React from "react";
import * as S from "./styled";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import BorderedTitle from "../BorderedTitle";

const Figure = ({ fluidImage, theme, mainTitle, mainSubtitle }) => (
  <ThemeProvider theme={theme || S.mainTheme}>
    <S.ContentWrapper>
      {mainTitle ? (
        <BorderedTitle
          title={mainTitle}
          align="center"
          dense
          color={theme.color || "var(--esx-black)"}
          borderColor="transparent"
        />
      ) : null}
      {mainSubtitle ? <S.MainSubtitle>{mainSubtitle}</S.MainSubtitle> : null}

      <S.FigureWrapper fluid={fluidImage}></S.FigureWrapper>
    </S.ContentWrapper>
  </ThemeProvider>
);

Figure.propTypes = {
  fluidImage: PropTypes.any,
  theme: PropTypes.object,
  mainTitle: PropTypes.string,
  mainSubtitle: PropTypes.string,
};

export default Figure;
